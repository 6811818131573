import React from 'react';
import Logo from '../../svg/Logo';
import linksJson from "../../content/header-content.json"
import { logAnalyticEvent } from '../../../firebase.ts';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

const formatAnchor = (link) => {
  const onClick = (section) => {
    logAnalyticEvent(`user_navigated_to_${section}`);
  }

  return (
    <AnchorLink striphash onClick={() => onClick(link.ref)} key={link.ref} className="h-auto my-auto py-auto px-2 lg:px-8 text-3xl font-bold text-gray-300 touch:text-grad xl:hover:text-grad" to={`/#${link.ref}`}>
      {link.text}
    </AnchorLink>
  );
};

const Header = ({isOnTop}) => (
  <header className="sticky top-0 shadow bg-black bg-opacity-10 backdrop-blur-sm z-50 transition-all duration-1000  ease-in-out">
    <div className={`container grid gap-y-4 justify-items-stretch items-center mx-auto py-4 px-8 bg-inherit grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 ${isOnTop ? 'grid-rows-2 sm:grid-rows-1' : 'grid-rows-1'} `}>
      <div className={`${isOnTop ? 'flex' : 'hidden sm:flex'} justify-center sm:justify-start items-center font-bold text-4xl h-16 col-start-1 col-span-1 row-start-1 row-span-1`}>
        <AnchorLink className="flex h-full w-auto justify-center md:mr-3" to="/#hero">
          <Logo className="w-full h-full" />
        </AnchorLink>
      </div>
      <div className={`flex flex-1 gap-x-6 sm:mx-3 justify-center items-center mt-0 col-start-1 col-span-1 sm:col-start-2 row-span-1 sm:row-start-1 ${isOnTop ? 'row-start-2' : 'row-start-1'}`}>
        {linksJson.map(link => formatAnchor(link))}
      </div>
    </div>
  </header>
);

export default Header;
