import React, { useState, useEffect, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import Footer from './Footer';
import Header from './Header';

const Layout = ({ children }) => {
  const [isScrolledToTop, setIsScrolledToTop] = useState(true);
  const callback = useCallback(
    () => {
      if (window.scrollY <= 10) {
        setIsScrolledToTop(true);
      }
      else if (window.scrollY > 160) {
        setIsScrolledToTop(false);
      }
    }, []
  );

  useEffect(() => {
    window.removeEventListener('scroll', callback);
    window.addEventListener('scroll', callback);
    return () => {
      window.removeEventListener('scroll', callback);
    };
  }, [callback]);


  return (
    <div className='scroll-smooth'>
      <Helmet>
        <title>Highlights NFT</title>
        <script src="https://kit.fontawesome.com/5f737f86d4.js" crossorigin="anonymous"></script>
        <script src="https://code.tidio.co/9q0cxytstsxns5bjxm2x2vemq5b4adrr.js" />
      </Helmet>
      <Header isOnTop={isScrolledToTop} />
      <main className=" text-gray-300">
        {children}
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
