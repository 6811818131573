// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { doc, setDoc, Timestamp, getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: process.env.FIREBASE_KEY,
    authDomain: process.env.FIREBASE_DOMAIN,
    projectId: process.env.FIREBASE_ID,
    storageBucket: process.env.FIREBASE_STORAGE,
    messagingSenderId: process.env.FIREBASE_SENDER,
    appId: process.env.FIREBASE_APPID,
    measurementId: process.env.FIREBASE_MEASUREMENT
};

let instance = null;
let analytics = null;
let db = null;

const init = (firebase) => {
    analytics = getAnalytics(firebase);
    db = getFirestore(firebase)
}

const getFirebase = () => {
    if (typeof window !== 'undefined') {
        if (instance) return instance;
        instance = initializeApp(firebaseConfig);
        return instance;
    }

    return null;
}

const logAnalyticEvent = (evnt) => {
    if (analytics) {
        logEvent(analytics, evnt);
    }
}

interface user {
    email: string,
    isStreamer: boolean,
    isViewer: boolean
}

const addEmail = async (user: user) => {
    try {
        logAnalyticEvent('adding_email_to_waiting_list');
        await setDoc(doc(db, "emails", user.email), {
            date: Timestamp.now(),
            email: user.email,
            isStreamer: user.isStreamer,
            isViewer: user.isStreamer
        });

        logAnalyticEvent('added_email_to_waiting_list');
    } catch (ex) {
        logAnalyticEvent('fail_to_add_email_to_waiting_list');
        console.log(ex);
    }
}

export { addEmail, logAnalyticEvent, init, getFirebase, user }