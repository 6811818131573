
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import React from 'react';

const Footer = () => (
  <footer className="flex w-full py-16 px-4 lg:px-32 mt-48 text-xl text-gray-300 shadow bg-grad">
    <div className='flex flex-col flex-1'>
      <div className="flex flex-1 flex-col lg:flex-row justify-evenly -mx-3">
        <div className="px-3">
          <div className='flex flex-grow-6 max-w-2xl flex-col'>
            <h2 className="text-2xl font-semibold">About Us</h2>
            <p className="mt-2">We are Highlights NFT, a new technolo-geek start-up that is tailored for content-creators, offering them a ticket into the world of Web3.</p>
          </div>
          <div className='mt-7 lg:mt-5 flex flex-col lg:flex-row gap-y-6'>
            <div className='flex flex-col'>
              <h2 className="text-2xl flex-grow-2 font-semibold">Contact us</h2>
              <p className="mt-2">contact@highlightsnft.com</p>
            </div>
            <div className='flex flex-1 flex-col items-start lg:items-end lg:mx-12 gap-y-4'>
              <AnchorLink to='/terms' className="text-2xl flex-grow-2 font-semibold">Terms of Use</AnchorLink>
              <AnchorLink to='/FAQ' className="hidden text-2xl flex-grow-2 font-semibold">FAQ</AnchorLink>
            </div>
          </div>
        </div>
        <div className="px-3 mt-7 lg:mt-0">
          <h2 className="text-2xl font-semibold">Social Media</h2>
          <ul className="mt-2 leading-10 min-w-max list-none pl-0">
            <li>
              <a href="https://twitter.com/HighlightsNFT" target="_blank" rel="noreferrer">
                <i className='fab fa-twitter' /> Twitter
              </a>
            </li>
            <li>
              <a href="https://facebook.com/HighlightsNFT" target="_blank" rel="noreferrer">
                <i className='fab fa-facebook' /> Facebook
              </a>
            </li>
            <li>
              <a href="https://discord.gg/Rm4VuNSb" target="_blank" rel="noreferrer">
                <i className='fab fa-discord' /> Discord
              </a>
            </li>
            <li>
              <a href="https://linkedin.com/company/highlights-nft" target="_blank" rel="noreferrer">
                <i className='fab fa-linkedin' /> LinkedIn
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
